@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
    font-size: 24px;
}

@font-face {
    font-family: 'Garet';
    src: url('../assets/Garet-Book.woff2') format('woff2'),
        url('../assets/Garet-Book.woff') format('woff'),
        url('../assets/Garet-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Garet';
    src: url('../assets/Garet-Heavy.woff2') format('woff2'),
        url('../assets/Garet-Heavy.woff') format('woff'),
        url('../assets/Garet-Heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    color: #36363E;
    background-color: #fffffd;
}

h1,
h2 {
    font-family: 'Garet';
}

h3 {
    font-family: 'Rubik', sans-serif;
}

h1 {
    font-size: 56px;
    line-height: 120%;
    font-weight: 850;
    text-align: center;
    margin: 0 auto;
}

h2 {
    font-size: 40px;
    line-height: 120%;
    font-weight: 900;
}

h3 {
    font-size: 24px;
    font-weight: 800;
    line-height: 150%;
    color: #0779B4;
    text-transform: uppercase;
}

h4 {
    color: #FFFFFD;
    font-family: 'Garet';
    font-size: 14px;
    font-style: normal;
    font-weight: 850;
    line-height: 150%;
    margin: 0;
    margin-bottom: 10px;
}

p {
    font-family: 'Rubik', sans-serif;
    font-size: 28px;
}

p .semi,
p.semi {
    font-weight: 700;
}

p.md {
    font-size: 24px;
}

p.small {
    font-size: 14px;
}

ul,
ul li,
ul ul li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
}

.background-blue {
    background-color: #30A8E6;
}

.background-blue-lite {
    background-color: #E6F5FB;
}

.background-white {
    background-color: #fffffd;
}

.background-animals {
    background-image: url("../img/Hero image - no bg op 50.png");
    background-size: 1000px;
    background-repeat: no-repeat;
    background-position: 50% calc(50% - 70px);
}


ul.nav li {
    float: left;
    margin-left: 20px;
}

ul.nav li a {
    font-family: 'Rubik';
    text-decoration: none;
    color: #36363E;
    font-weight: 600;
    font-size: 20px;
}

.footer ul li , .footer ul li a{
    color: #FFFFFD;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    text-decoration: none;
}

ul.nav li a:hover, .footer ul li a:hover {
    text-decoration: underline;
}

/* CSS */
button.cta {
    align-items: center;
    background-color: #5100FF;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #fffffd;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Rubik';
    justify-content: center;
    letter-spacing: 0.18px;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 180px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    height: 56px;
}

.rounded {
    border-radius: 25px;

}

/* .button-18:hover,
.button-18:focus { 
  background-color: #16437E;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
}

.button-18:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
} */
.c-blue {
    color: #30A8E6
}

.c-white {
    color: #fffffd
}

.mt-20 {
    margin-top: 20px;
}
.mt-40 {
    margin-top: 40px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-0 {
    margin-bottom: 0px;
}
.pb-20 {
    padding-bottom: 20px;
}

.pt-70 {
    padding-top: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.m-w550 {
    max-width: 550px;
}

@media screen and (max-width: 800px) {
    .div-mobile { display: none; }
}
